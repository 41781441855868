export default theme => ({
    wrapper: {
        background: 'transparent',
        border: 0,
        outline: 'none',
        padding: 0,
        maxWidth: '100%',

        '&:hover': {
            background: 'transparent'
        },
        '& .ba-videoplayer-container': {
            top: 2,
            // minHeight: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                maxHeight: 173
            }
        },
        '& .video_player': {
            maxHeight: 260,
            '& video': {
                maxHeight: 260
            },
            '& audio': {
                minWidth: 300
            },
            [theme.breakpoints.down('lg')]: {
                maxHeight: 163,
                '& video': {
                    maxHeight: 163
                },
                '& audio': {
                    minWidth: 220
                }
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: 173,
                '& video': {
                    maxHeight: 173
                },
                '& audio': {
                    minWidth: 245
                }
            }
        },
        '& .ba-audioplayer-container.ba-player-no-title': {
            minWidth: 'auto'
        },
        '& .playerWrapperAudio': {
            border: 'none!important',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                maxHeight: 173
            }
        }

    }
});
