import React from 'react';
import VideoPlayer from 'components/media_player/video_player';

const MediaQuestion = ({ ziggeo, audio }) => {
    if (!(ziggeo && ziggeo.media_file_url)) return null;
    return (
        <div className="u-txt--center">
            <VideoPlayer ziggeo={ziggeo} audio={audio} />
        </div>
    );
};

export default MediaQuestion;
