import React from 'react';
import VideoPlayer from 'components/media_player/video_player';
import { withStyles } from '@mui/styles';
import styles from './styles';

const AnswerOptionMedia = ({ answer: { ziggeo, type }, classes }) => {
    const { wrapper } = classes;
    if (!ziggeo || !ziggeo.media_file_url) return null;

    const handleClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div role="contentinfo" className={wrapper} onClick={handleClick}>
            <VideoPlayer audio={type === 3} ziggeo={ziggeo} />
        </div>
    );
};

export default withStyles(styles)(AnswerOptionMedia);
